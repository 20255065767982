

    import $request from "@/modules/request/"

    export default {

        dataSource: {
            current_page: 1,
            has_more_pages: false,
            last_page: 1,
            total_count: 0,
            items: []
        },

        count_unread: 0,
        first_open: false,
        

        get(page=1) {
            $request.get("notifications.list", {page}).then( rsp => {
                this.count_unread = rsp.count_unread;
                Object.keys( rsp ).forEach( key => {
                    if( this.dataSource.hasOwnProperty( key ) ) {
                        this.dataSource[ key ] = rsp[key];
                    }
                });
            });
        },

        reading() {
            $request.post("notifications.reading").then( rsp => {
                this.count_unread = 0;
            });
        }

    }
