export default {
    ru: {
        code: "ru",
        country: "Russia",
        flag: require('@/assets/images/flags/ru.svg'),
        abb: "RUS"
    },
    en: {
        code: "en",
        country: "English",
        flag: require('@/assets/images/flags/en.png'),
        abb: "ENG"
    }
    // ar: "عربي (Arabic)"
}