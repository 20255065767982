
export default [
  {
    title: 'navigation.home',
    route: 'user-dashboard',
    icon: 'home',
    side: "bottom",
  },
  // {
  //   title: 'navigation.cloud',
  //   icon: 'cloud',
  //   side: "right",
  //   visible: false,
  //   children: [
  //     { icon: 'sub', title: 'navigation.my-contracts', group: "user", route: "user-contracts-list" },
  //     { icon: 'sub', title: 'navigation.rent', group: "user", route: "user-contracts-create" },
  //     {
  //       icon: 'sub', title: 'navigation.statistics', group: "user", route: {
  //         name: "user-statistic-cloud",
  //         params: {
  //           group: "cloud"
  //         }
  //       }
  //     },
  //   ]
  // },
  {
    title: 'navigation.statistic',
    route: 'user-statistics',
    icon: 'statistic',
    side: "bottom",
  },
  // {
  //     title: 'navigation.create',
  //     icon: 'plus',
  //     side: "bottom",
  //     visible: false,
  //     route: "user-contracts-picker"
  // },
  {
    title: 'navigation.orders',
    route: 'user-orders',
    icon: 'orders',
    side: "bottom",
  },
  {
    title: 'Сообщения',
    route: 'user-requests',
    icon: 'requests',
    side: "bottom",
  },
  // {
  //   title: 'navigation.orders',
  //   route: 'user-orders',
  //   icon: 'transactions',
  //   side: "right",
  // },
  // {
  //     title: 'Транзакции',
  //     route: 'user-transactions',
  //     icon: 'transactions',
  //     side: "bottom",
  // },
  {
    title: 'Команда',
    route: 'user-news',
    icon: 'users',
    side: "right",
    group: "user"
  },
  {
    title: 'Новости',
    route: 'user-news',
    icon: 'news',
    side: "right",
    group: "user"
  },
  // {
  //   title: 'navigation.docs',
  //   route: 'user-documents',
  //   icon: 'documents',
  //   side: "right",
  // },
  // {
  //   title: 'navigation.affiliate-program',
  //   route: 'user-referral',
  //   icon: 'referral',
  //   side: "right",
  // },
  // {
  //   title: 'navigation.settings',
  //   route: 'user-account',
  //   icon: 'settings',
  //   side: "right",
  // }
]