<template>
  <div
    class="navbar-container d-flex content align-items-center "
  >
    <!--      <div>-->
    <ul v-if="User.isAuth()" class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav">
      <!-- <div v-if="User.isAuth()" class="d-flex flex-column" style="padding-top: 8px;">
                    <b-link :to="{ name: 'user-account' }" class="mb-0 text-black" style="font-weight: 500;">{{ user.firstName }} {{ user.lastName }}</b-link>
                    <b-link @click="logout" class="text-muted" style="font-size: 12px;">Выйти</b-link>
                </div>   -->

      <li v-if="!User.isAuth()" class="nav-item mr-auto">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width: 32px; height: 32px; margin-right: 8px;"
            />
          </span>
          <span class="brand-text">
            <b-img
              v-if="skin.value === 'dark'"
              src="@/@core/assets/images/logo_dark.svg"
              alt="logo"
            />
            <b-img v-else src="@/@core/assets/images/logo_dark.svg" alt="logo" />
          </span>
        </b-link>
      </li>
    </b-navbar-nav>

    <div
      v-if="User.isAuth()"
      class="d-flex bookmark-wrapper align-items-center mr-0"
    >

      <b-navbar :style="'z-index: 10'" class="main-navbar px-md-0" toggleable="lg">
        <b-container class="container-px-lg">
          <ul class="nav navbar-nav flex-row">

            <!-- Logo & Text -->
            <li class="nav-item mr-auto">
              <b-link
                class="navbar-brand"
                to="/"
              >
                <span class="brand-logo">
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                  />
                </span>
                <span class="brand-text">
                  <b-img
                    v-if="skin === 'dark'"
                    src="@/@core/assets/images/2work_light.svg"
                    alt="logo"
                  />
                  <b-img
                    v-else
                    src="@/@core/assets/images/2work.svg"
                    alt="logo"
                  />
                </span>
              </b-link>
            </li>
          </ul>
          <b-navbar-nav class="ml-auto d-none d-xl-flex">
            <div v-for="(link, index) in links" :key="index" class="mr-0 mr-xl-1 my-auto dynamic-header-links">
              <router-link v-if="link.to"
                          :class="$route.path === link.to && 'active-link'"
                          :exact="link.exact"
                          :to="link.to"
                          active-class="active-link"
                          style="padding: 1px 6px"
              >
                {{ link.name }}
              </router-link>
              <button v-else @click="linkHandler(link.id, link.action)">
                {{ link.name }}
              </button>
            </div>
          </b-navbar-nav>
        </b-container>
      </b-navbar>
      
      <template>
        <b-nav-item-dropdown class="select-language const-btn d-md-flex" right>
          <template #button-content class="const-btn">
            <img alt="" class="select-language-flag my-auto" :src="locale.flag">
            <span class=" call-selector">{{locale.abb}}</span>
          </template>
          <b-dropdown-item v-for="(item, index) in locales" :key="index" @click="setLocale(item.code)">
            <img alt="" class="select-language-flag my-auto" :src="item.flag">
            {{item.abb}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <div>
          <a @click="toggleNotification">
            <span
              v-if="notifications.count_unread > 0"
              class="notif-counter"
              style="top: 20px;"
              >{{ notifications.count_unread > 9 ? '9+' : notifications.count_unread }}</span
            >
            <img
              v-if="skin.value === 'dark'"
              style="margin-top: 8px"
              alt="notification"
              class="notification"
              src="@/assets/images/icons/notification-light.svg"
            />
            <img
              v-else
              style="margin-top: 8px"
              alt="notification"
              class="notification"
              src="@/assets/images/icons/notification.svg"
            />
          </a>
        </div>
        <div class="d-flex flex-column ml-1" style="padding-top: 8px;">
          <b-link
            :to="{ name: 'user-account' }"
            class="mb-0 text-black"
            style="font-weight: 500;"
            >{{ user.first_name }} {{ user.last_name }}</b-link
          >
          <b-link @click="logout" class="text-muted" style="font-size: 12px;"
            >{{$t('navigation.log-out')}}</b-link
          >
        </div>
        <b-button
          v-if="User.self && User.self.group > 1"
          :to="getCurrentRoute()"
          target="_blank"
          class="ml-1"
          variant="outline-primary"
          style="border-radius: 10px !important; border-width: 2px !important; font-weight: 500 !important; width: 44px; height: 44px; padding: 0; line-height: 30px;"
        >
          <feather-icon icon="SettingsIcon" size="22" />
        </b-button>
        <notification-view v-if="showNotif" />
      </template>
    </div>

    <b-navbar-nav
      v-else
      class="nav align-items-center ml-auto login"
      style="background-color: #000; border-radius: 50px; height: 36px;"
    >
      <b-nav-item class="m-0 p-0">
        <feather-icon
          icon="UserIcon"
          style="color: #FFF; margin-right: 4px;"
          size="20"
        />
      </b-nav-item>
      <b-nav-item :to="{ name: 'auth-login' }">
        Вход
      </b-nav-item>
      <b-nav-item class="m-0 p-0">
        /
      </b-nav-item>
      <b-nav-item :to="{ name: 'auth-register' }">
        Регистрация
      </b-nav-item>
    </b-navbar-nav>

    <!--</div>-->
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import { $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

import { showAt, hideAt } from "vue-breakpoints";
import User from "@/modules/user";

import NotificationView from "@/components/notification/notification.desktop";
import notifications from "@/modules/contents/notifications";

import { getSupportedLocales } from "@/libs/i18n/modules/supported-locales"


export default {
  data() {
    return {
      User,
      notifications,
      showNotif: false,
      locales: getSupportedLocales(),
      locale: {
        flag: null,
        abb: null
      },
      links: [
        {to: "/", name: "Статистика", exact: false},
        {to: "/", name: "Команда", exact: false},
        {to: "/", name: "Обучение", exact: false},
      ],
      showMobileMenu: false,
    };
  },

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    showAt,
    NotificationView,
  },

  methods: {
    logout() {
      this.$request.post("sessions.finish").then(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("current:user");
        sessionStorage.removeItem("access_token");
        localStorage.removeItem("showingWelcome");
        
        // Remove dark mode
        if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

        this.$router.replace("/authorization");
      });
    },

    getCurrentRoute() {
      if (this.$route.meta.group == "administrator")
        return { name: "user-dashboard" };
      else return { name: "admin" };
    },

    toggleNotification() {
      this.showNotif = !this.showNotif;

      if (!this.notifications.first_open) {
        this.notifications.first_open = true;
        this.notifications.reading();
      }
    },

    eventToggleNotification(e) {
      if (e.target.className != "notification" && this.showNotif) {
        this.showNotif = false;
      }
    },

    setLocale(locale) {
      this.$i18n.locale = locale
      this.locale = this.locales.find(loc => loc.code === locale);
      User.changeLocale(locale);
    }
  },

  mounted() {
    if( User.isAuth() ) {
      // this.notifications.get();
    }
    this.locale = this.locales.find(loc => loc.code === this.$i18n.locale);
  },

  created() {
    window.addEventListener("click", this.eventToggleNotification);
  },

  destroyed() {
    window.removeEventListener("click", this.eventToggleNotification);
  },

  computed: {
    ticker() {
      return User.currency;
    },
    user() {
      return User.self;
    },
    skin() {
      const { skin } = useAppConfig();
      return skin;
    },
    appLogoImage() {
      const { appName, appLogoImage } = $themeConfig.app;
      return appLogoImage;
    },
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.notif-counter {
  padding: 1px 5px;
}
.select-language {
  border: 1px solid rgba(26, 86, 255, 0.18);
  border-radius: 35px;
  margin: 0px 20px;
  margin-top: 9px;
  template #button-content {
    color: var(--theme-accent-light);
  }

  .select-language-flag {
    margin-right: 5px;
    height: 18px;
  }

  margin-left: -5px;
  display: block;
  padding: 2px 10px;
}

.mobile-nav-item {
  .active-link {
    color: var(--theme-accent-light) !important;
  }
}

.main-navbar {
  padding: 24px 20px;
  transition: all .3s ease;
  background: none;
}

html[md="true"] {
  .main-navbar {
    background: white;
  }
}

.nav-bar-toggle {
  outline: none;
  background: none;
  border: none;
  transition-delay: 0.3s;
  margin-top: 10px;

  img {
    height: 24px;
  }
}

.dropdown-toggle::after {
  border: none !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  padding: 0;
  margin: 0;
}
</style>

