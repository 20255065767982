<template>
    <div ref="root" style=" position: relative; transform: translateY(155px); transition-property: all; transition-duration: .35s; z-index: 1000;">

        <!-- <vue-bottom-sheet ref="sheet" @closed="sheetClosed" max-width="100%" max-height="100%" :swipe-able="true">
            <b-card no-body>
                <b-card-header>
                    <b-card-title style="font-size: 20px;">
                        {{$t('cart.header')}}
                    </b-card-title>
                </b-card-header>

                <b-card-body>
                    
                </b-card-body>

            </b-card>
            <div class="d-flex cart-view justify-content-between align-items-center" style="position: fixed; left: 0; right: 0; bottom: 0; padding: 20px 32px; height: 95px; z-index: 99">
                <div>
                    <span class="text-muted" style="font-size: 14px;">{{selectedPlaceholder}} {{ countOfSelected }}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ summary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>

                <div>
                    <span class="text-muted" style="font-size: 14px;">{{$t('cart.guarantees')}}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ guaranteeSummary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>

                <div>
                    <span class="text-muted" style="font-size: 14px;">{{$t('cart.total-guarantee')}}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ totalSummary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>

                <div class="d-flex">
                    <b-button @click="clear" variant="gray" style="margin-right: 16px; border-radius: 10px;">{{$t('cart.clear')}}</b-button>
                    <b-button @click="goToCheckOut" variant="danger" style="border-radius: 10px;">{{$t('cart.checkout')}}</b-button>
                </div>
            </div>
        </vue-bottom-sheet>

        <showAt breakpoint="large">
            <div ref="cart" @click="showCartView" class="d-flex cart-view justify-content-between align-items-center" style=" padding: 20px 32px;  cursor: pointer; height: 95px; box-shadow: 0px -10px 14px rgba(99, 109, 137, 0.05); border-top-left-radius: 24px; border-top-right-radius: 24px; transition-property: all; transition-duration: .22s; z-index: 1000;">
                <div>
                    <span class="text-muted" style="font-size: 14px;">{{selectedPlaceholder}} {{ countOfSelected }}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ summary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>

                <div>
                    <span class="text-muted" style="font-size: 14px;">{{$t('cart.guarantees')}}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ guaranteeSummary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>

                <div>
                    <span class="text-muted" style="font-size: 14px;">{{$t('cart.total-guarantee')}}</span>
                    <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 500;">
                        {{ totalSummary.formatMoney(0," "," ") }} <span class="text-muted">$</span>
                    </p>
                </div>


                <div class="d-flex">
                    <b-button @click="clear" variant="gray" style="margin-right: 16px; border-radius: 10px;">{{$t('cart.clear')}}</b-button>
                    <b-button @click="goToCheckOut" variant="danger" style="border-radius: 10px;">{{$t('cart.checkout')}}</b-button>
                </div>

            </div>
        </showAt>

        <showAt breakpoint="mediumAndBelow">
            <div ref="cart" class="mobile-cart-btn" style="position: fixed; right: 16px; bottom: 176px; width: 50px; height: 50px; border-radius: 50%; background: #426FF6; line-height: 50px; text-align: center; color: #FFF; box-shadow: 0px -10px 14px rgba(99, 109, 137, 0.05); transition-property: all; transition-duration: .22s;">
                <div v-ripple @click="goToCheckOut" style="width: 100%; height: 100%; border-radius: 50%;">
                    <feather-icon icon="ShoppingCartIcon" size="22" />
                </div>
            </div>
        </showAt>

        <b-modal id="miners-modal" 
            modal-class="custom-contains guarantee-modal" 
            v-model="modalsVisibility.miners" 
            centered 
            hide-footer
            size="lg"
        >
            <miners-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="hashrate-modal" 
            modal-class="custom-contains guarantee-modal" 
            v-model="modalsVisibility.hashrate" 
            centered 
            hide-footer
            size="lg"
        >
            <hashrate-modal-content @close="closeModal" />
        </b-modal> -->

    </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
// import MinerCartCard from "@/components/MinerCartCard/"

// import miners from "@/modules/contents/miners";
import Utils from "@/modules/utils/";

// import MinersModalContent from "@/views/User/miners/item/views/modals/guarantee/miners"
// import HashrateModalContent from "@/views/User/miners/item/views/modals/guarantee/hashrate"

export default {
  data() {
    return {
      // miners,

      guarantee: {
        period: "none",
        ths: false,
        amount: 0,
      },

      modalsVisibility: {
        miners: false,
        hashrate: false,
      },
    };
  },
  props: {
    isVerticalMenuCollapsed: Boolean,
  },
  methods: {
    goToCheckOut(args) {
    
    },

    clear(args) {
     
    },

    preferCountValueChanged(miner, count) {
      if (count === 0) {
      }
    },

    sheetClosed(args) {
      this.$refs.cart.style.transform = "translate(0, 0)";
    },

    showCartView(args) {
      if (this.cart.length === 0) return;
      this.$refs.cart.style.transform = "translate(0, 125px)";
      this.$refs.sheet.open();
    },

    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },

    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
  },
  components: {
    VueBottomSheet,
  },
  computed: {
    

    cart() {
      return [];
    },
  },
  watch: {
    "cart.length": function (len) {
      if (len === 0) this.$refs.sheet.close();
    },
  },
  beforeDestroy() {
    this.$refs.root.style.transform = "translateY(155px)";
  },
  mounted() {
    this.$nextTick(() => (this.$refs.root.style.transform = "translateY(0px)"));
  },
};
</script>

<style lang="scss">
.cart-view {
  background-color: #fff;
}

.dark-layout .cart-view {
  background-color: #262642;
}

.dark-layout .bottom-sheet__card {
  background-color: #262642;
}

@media (min-width: 1280px) {
  .menu-expanded .bottom-sheet__card {
    left: 0px !important;
    width: unset !important;
    right: 0px;
    transform: translate(0, 0) !important;
    min-height: 100vh;
  }

  .menu-collapsed .bottom-sheet__card {
    left: 0px !important;
    width: unset !important;
    right: 0px;
    transform: translate(0, 0) !important;
    min-height: 100vh;
  }
}
</style>

<style lang="scss" scoped>
.container {
  margin: 0px;
  width: auto;
}
.guarantee-list {
  padding-bottom: 8px;
  padding-top: 8px;

  span,
  label {
    margin-right: 25px;
  }
}
</style>


