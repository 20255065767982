<template>
  <transition duration="550" name="nested">
    <div
      class="d-flex notif-mobile-wrapper"
      style="flex-direction: column; align-items: end;"
    >
      <div class="notif-mobile-close">
        <a data-action="close" @click="$emit('close')">
          <feather-icon
            icon="XIcon"
            size="26"
            style="background-color: #9ca2b140; border-radius: 50px; color: #9CA2B1; padding: 2px"
          />
        </a>
      </div>
      <div
        class="d-flex notif-mobile outer"
        style="padding: 24px 0px; padding-top: 60px"
      >
        <div class="card-body">
          <div class="inner" style="padding: 0px">
            <b-card-title class="mb-1" style="font-size: 20px">
              {{$t('notifications')}}
            </b-card-title>

            <item-view
              v-for="(item, index) in notifications.dataSource.items"
              :key="index"
              :item="item"
            />

            <b-card-body
              v-if="notifications.dataSource.items.length == 0"
              class="notif-item"
              style="padding: 0px 0px 12px 0px; margin-bottom: 12px"
            >
              <b-card-text
                class="text-muted"
                style="font-size: 14px; line-height: 20px"
              >
                Нет данных для отображения
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ItemView from "./item.notification";
import notifications from "@/modules/contents/notifications";

export default {
  data() {
    return {
      notifications,
    };
  },
  components: {
    ItemView,
  },
};
</script>

<style lang="scss">
.notif-mobile {
  position: absolute;
  right: 0px;
  .card-body:nth-child(1) {
    padding: 0px 24px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.notif-mobile-close {
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
}

.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>
