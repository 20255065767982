<template>
  <div
    class="navbar-container d-flex content align-items-center justify-content-between"
  >
    <div class="mr-auto">
      <b-link to="/">
        <b-img
          v-if="skin.value === 'light'"
          src="~@/assets/images/logo/logo_title_flat.png"
          fluid
          width="110px"
        />
        <b-img
          v-else
          src="~@/assets/images/logo/logo_title_flat_white.png"
          fluid
          width="110px"
        />
      </b-link>
    </div>

    <div class="bookmark-wrapper align-items-center ml-auto mr-0">
      <div v-if="User.isAuth()" class="d-flex align-items-center">
        <div>
          <a @click="toggleNotification">
            <span
              v-if="notifications.count_unread > 0"
              class="notif-counter"
              style="top: 14px;"
              >{{ notifications.count_unread }}</span
            >
            <img
              v-if="skin.value === 'dark'"
              style="margin-top: 5px; width: 17px; height: 20px"
              alt="notification"
              class="notification"
              src="@/assets/images/icons/notification-light.svg"
            />
            <img
              v-else
              style="margin-top: 5px; width: 17px; height: 20px"
              alt="notification"
              class="notification"
              src="@/assets/images/icons/notification.svg"
            />
          </a>
        </div>
        <b-link
          :to="{ name: 'user-account' }"
          class="ml-1 mb-0 text-black"
          style="font-weight: 500;"
          >{{ user.lastName }} {{ user.firstName[0] }}.</b-link
        >
        <img
          @click="logout"
          alt=""
          src="@/assets/images/site/logout.svg"
          class="ml-1"
          style="width: 20px"
        />

        <showAt breakpoint="medium">
          <notification-view-tablet v-if="showNotif" />
        </showAt>

        <showAt breakpoint="small">
          <notification-view-mobile
            v-if="showNotif"
            @close="closeNotification"
          />
        </showAt>
      </div>
      <b-navbar-nav
        v-else
        class="nav align-items-center ml-auto login"
        style="background-color: #000; border-radius: 50px; height: 36px;"
      >
        <b-nav-item class="m-0 p-0">
          <feather-icon
            icon="UserIcon"
            style="color: #FFF; margin-right: 4px;"
            size="20"
          />
        </b-nav-item>
        <b-nav-item :to="{ name: 'auth-login' }">
          Вход
        </b-nav-item>
        <b-nav-item class="m-0 p-0">
          /
        </b-nav-item>
        <b-nav-item :to="{ name: 'auth-register' }">
          Регистрация
        </b-nav-item>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import { showAt, hideAt } from "vue-breakpoints";
import User from "@/modules/user";
import useAppConfig from "@core/app-config/useAppConfig";
// import { provide, computed, ref } from '@vue/composition-api'

import NotificationViewTablet from "@/components/notification/notification.desktop";
import NotificationViewMobile from "@/components/notification/notification.mobile";

import notifications from "@/modules/contents/notifications";

export default {
  data() {
    return {
      User,
      notifications,
      skin: useAppConfig().skin,
      showNotif: false,
      firstOpen: false,
    };
  },

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    showAt,
    NotificationViewTablet,
    NotificationViewMobile,
  },

  methods: {
    logout() {
      this.$request.post("sessions.finish").then(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("current:user");
        sessionStorage.removeItem("access_token");

        // Remove dark mode
        if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

        this.$router.replace("/authorization");
      });
    },

    toggleNotification() {
      this.showNotif = !this.showNotif;

      if (!this.notifications.first_open) {
        this.notifications.first_open = true;
        this.notifications.reading();
      }
    },

    closeNotification() {
      this.showNotif = false;
    },

    eventToggleNotification(e) {
      if (e.target.className != "notification" && this.showNotif) {
        this.showNotif = false;
      }
    },
  },

  computed: {
    ticker() {
      return User.currency;
    },
    user() {
      return User.self;
    },
  },
  mounted() {
    if( User.isAuth() ) {
      // this.notifications.get();           
    }
  },

  //    created() {
  //     window.addEventListener("click", this.eventToggleNotification);
  //   },

  //   destroyed() {
  //     window.removeEventListener("scroll", this.eventToggleNotification );
  //   },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.notif-counter {
  padding: 0px 4px;
}
</style>
